import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import { Experiment } from '../constants';

export const getReorderedItems = <T>(
  sourceIndex: number,
  targetIndex: number,
  items: T[],
) => {
  const itemsCopy = [...items];
  const [itemToAdd] = itemsCopy.splice(sourceIndex, 1);

  itemsCopy.splice(targetIndex, 0, itemToAdd);

  return itemsCopy;
};

export const isCurrentLanguageOriginal = async (editorSDK: FlowEditorSDK) => {
  const language = editorSDK?.language;

  if (await language.multilingual.isEnabled('')) {
    const currentLanguageCode = await language.current.get('');
    const originalLanguage = await language.original.get('');

    return currentLanguageCode === originalLanguage.languageCode;
  }

  return true;
};

export const getLayoutPanelHeight = (
  experiments: EditorScriptFlowAPI['experiments'],
  environment: EditorScriptFlowAPI['environment'],
) => {
  const showCascadingIndicators =
    environment.isEditorX &&
    experiments.enabled(Experiment.EnableCSSIndicators);

  if (showCascadingIndicators) {
    return 233;
  }

  return 200;
};
